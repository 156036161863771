<template>
  <b-card>
    <b-row>
      <b-col cols="12">
        <!-- <b-button
          class="mr-1"
          size="sm"
          variant="primary"
          type="button"
        >Generate Perawatan</b-button> -->
        <b-button
          class="mr-1"
          size="sm"
          variant="success"
          type="button"
          @click="addMaintenance"
        >Tambah Perawatan</b-button>
        <b-button
          class="mr-1"
          size="sm"
          variant="info"
          type="button"
          @click="costMonth"
        >Biaya Per Bulan</b-button>
      </b-col>
    </b-row>
    <br>
    <b-tabs>
      <b-tab active>
        <template #title>
          All
          &nbsp;
          <span class="badge bg-primary circle">{{ generated+request+plan+realisation+done }}</span>
        </template>
        <index />
      </b-tab>
      <b-tab>
        <template #title>
          Generated
          &nbsp;
          <span class="badge bg-primary circle">{{ generated }}</span>
        </template>
        <index status="generate" />
      </b-tab>
      <b-tab>
        <template #title>
          Pengajuan
          &nbsp;
          <span class="badge bg-primary circle">{{ request }}</span>
        </template>
        <index status="request" />
      </b-tab>
      <b-tab>
        <template #title>
          Rencana
          &nbsp;
          <span class="badge bg-primary circle">{{ plan }}</span>
        </template>
        <index status="plan" />
      </b-tab>
      <b-tab>
        <template #title>
          Perawatan
          &nbsp;
          <span class="badge bg-primary circle">{{ realisation }}</span>
        </template>
        <index status="realisation" />
      </b-tab>
      <b-tab>
        <template #title>
          Selesai
          &nbsp;
          <span class="badge bg-primary circle">{{ done }}</span>
        </template>
        <index status="done" />
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>

import {BCard, BRow, BCol, BTabs, BTab, BButton} from 'bootstrap-vue'
import Index from './maintenance/Index.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTabs, 
    BTab,
    BButton,
    Index
  },
  data(){
    return{
      generated:0,
      request:0,
      plan:0,
      realisation:0,
      done:0
    }
  },
  mounted(){
    this.fetchDataCount()
  },
  methods:{
    fetchDataCount(){
      this.$http.get('vehicle/'+this.$route.params.id+'/maintenance/count').then(res=>{
        let data = res.data.data
        for(let i=0; i<data.length; i++){
          if(data[i].status == 'request'){
            this.request = data[i].count
          }
          if(data[i].status == 'plan'){
            this.plan = data[i].count
          }
          if(data[i].status == 'generated'){
            this.generated = data[i].count
          }
          if(data[i].status == 'realisation'){
            this.realisation = data[i].count
          }
          if(data[i].status == 'done'){
            this.done = data[i].count
          }
        }
      })
    },
    addMaintenance(){
      this.$router.push({ name: 'rencana-perawatan-create' })
    },
    costMonth(){
      this.$router.push({ name: 'vehicles-cost' })
    }
  }
}
</script>
