<template>
  <b-tabs
    vertical
    nav-wrapper-class="nav-vertical"
  >
    <b-tab :active="tabActive == 'dashboard'">
      <template #title>
        <feather-icon icon="LayoutIcon" />
        Dashboard
      </template>
      <dashboard />
    </b-tab>
    <b-tab :active="tabActive == 'info'">
      <template #title>
        <feather-icon icon="InfoIcon" />
        Info Detail
      </template>
      <info />
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon icon="SettingsIcon" />
        Perawatan
      </template>
      <maintenance />
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon icon="ListIcon" />
        History Mutasi
      </template>
      <mutasi />
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon icon="ListIcon" />
        Kelengkapan
      </template>
      <properties />
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon icon="ListIcon" />
        Body
      </template>
      <bodies />
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon icon="ListIcon" />
        Berkas Upload
      </template>
      <document />
    </b-tab>
  </b-tabs>
</template>

<script>

import {BRow,BCol,BTabs, BTab} from 'bootstrap-vue'
import Info from './include/Info.vue'
import Dashboard from './include/Dashboard.vue'
import Maintenance from './include/Maintenance.vue'
import Mutasi from './include/Mutasi.vue'
import Bodies from './include/Bodies.vue'
import Properties from './include/Properties.vue'
import Document from './include/Document.vue'

export default {
  components: {
    BRow,
    BCol,
    BTabs, 
    BTab,
    Info,
    Dashboard,
    Maintenance,
    Mutasi,
    Bodies,
    Properties,
    Document
  },
  data(){
    return {
      tabActive:'dashboard'
    }
  },
  created(){
    if(this.$route.params.tabActive)
      this.tabActive = this.$route.params.tabActive
  }
}
</script>
<style>
[dir] .nav-vertical .nav.nav-tabs .nav-item .nav-link {
  margin-bottom: 0; 
  justify-content: left;
}
</style>
