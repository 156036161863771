<template>
  <b-row>
    <b-col cols="12">
      <basetable
        ref="basetable"
        :dataurl="dataurl"
        :fields="fields"
        :baseroute="baseroute"
        :is-edit="false"
        :is-delete="false"
        :is-add="false"
        :filter="filter"
      >
        <template #cell(_)="data">
          <b-button
            size="sm"
            variant="primary"
            class="mr-1 btn-icon"
            @click="viewForm(data)"
          >
            <feather-icon icon="FolderIcon" />
          </b-button>
        </template>
        <template #cell(status)="data">
          <span
            v-if="data.item.status == 'request'"
            class="badge bg-info"
          >
            Pengajuan
          </span>
          <span
            v-if="data.item.status == 'plan'"
            class="badge bg-info"
          >
            Rencana
          </span>
          <span
            v-if="data.item.status == 'realisation'"
            class="badge bg-success"
          >
            Perawatan
          </span>
          <span
            v-if="data.item.status == 'reject'"
            class="badge bg-danger"
          >
            Ditolak
          </span>
          <span
            v-if="data.item.status == 'done'"
            class="badge bg-success"
          >
            Selesai
          </span>
        </template>
      </basetable>
    </b-col>
  </b-row>
</template>
<script>
import basetable from '@/views/base/BaseTable.vue'
import { BCard,BRow,BCol, BButton, BTable, BOverlay } from 'bootstrap-vue'

export default {
  components: {
    basetable,
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BOverlay
  },
  props:{
    status:{
      type:String,
      default:'all'
    }
  },
  data() {
    return {
      dataurl:"/vehicle/"+this.$route.params.id+"/maintenance",
      baseroute:"perawatan",
      fields: [
        // { key: 'code', label: 'Code', sortable: true },
        { key: 'description', label: 'Deskripsi', sortable: true },
        { key: 'plan_km', label: 'KM Rencana', sortable: true },
        { key: 'plan_date', label: 'Tanggal Rencana', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
        { key: 'plan_cost', label: 'Rencana Biaya', sortable: true }
      ],
      filter:[]
    }
  },
  created(){
    if(this.status != 'all'){
      this.filter['filter[status]'] = this.status
    }
  },
  mounted(){
    // if(this.status != 'all')
    //   this.show()
  },
  methods:{
    show(){
      this.$refs.basetable.fetchData()
    },
    viewForm(data){
      this.$router.push({ name: this.baseroute+'-detail',params : { id: data.item.id} })
    },
  }
}
</script>
<style lang="">

</style>
