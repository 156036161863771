<template>
  <b-card>
    <b-row>
      <b-col cols="6">
        <base-info
          :geturl="dataurl"
          :fields="fields"
          :isdelete="false"
          :isedit="false"
          title="Info Detail"
        />
      </b-col>
      <b-col cols="6">
        <base-info
          ref="infoAdministrasi"
          :geturl="dataurl"
          :fields="fieldsAdministrasi"
          :isdelete="false"
          :isedit="false"
          :isAction="isAction"
          @readData="showData"
          title="Administrasi" >

          <template v-slot:buttonaction>
            <b-button
              v-if="is_update_stnk"
              class="mr-1"
              size="sm"
              variant="primary"
              type="button"
              @click="showModalTax('stnk')"
            >
              
              <span>Perbarui STNK</span>
            </b-button>

            <b-button
              v-if="is_update_keur"
              class="mr-1"
              size="sm"
              variant="primary"
              type="button"
              @click="showModalTax('keur')"
            >
              
              <span>Perbarui KIR</span>
            </b-button>
          </template>
          
        </base-info>
      </b-col>
      <b-col cols="12">
        <hr>
      </b-col>
      <b-col cols="6">
        <base-info
          ref="infoOperational"
          :geturl="dataurl"
          :fields="fieldsOperasional"
          :isdelete="false"
          :isedit="false"
          title="Operasional"
          @readData="showDataOperasional"
        />
      </b-col>
      <b-col cols="6">
        <base-info
          :geturl="dataurl"
          :fields="fieldsFisik"
          :isdelete="false"
          :isedit="false"
          title="Fisik"
        />
      </b-col>
    </b-row>
    <b-modal
      ref="modal-request-tax"
      :title="'tanggal bayar pajak'"
      centered
      hide-footer
    >
      <b-form-group
        label-cols-md="4"
        label="Tanggal"
      >
        <flat-pickr
          v-model="tax_date"
          class="form-control"
          placeholder="Tanggal Bayar Pajak"
        />
      </b-form-group>

      <div class="form-group mt-2 float-right">
        <b-button
          class="mr-1"
          variant="warning"
          data-dismiss="modal"
          @click="$refs['modal-request-tax'].hide()"
        >
          Batal
        </b-button>
        <b-button
          variant="primary"
          @click.prevent="updateTax"
        >
          Kirim
        </b-button>
      </div>
    </b-modal>
  </b-card>
</template>

<script>

import {BCard, BRow, BCol, BButton, BModal, BFormGroup} from 'bootstrap-vue'
import BaseInfo from '@/views/base/BaseInfo.vue'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import store from '@/store'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BaseInfo,
    BButton,
    BModal,
    BFormGroup,
    flatPickr
  },

  data() {
    return {
      userType:null,
      dataurl:"/vehicle",
      fields: [
        // { key: 'code', label: 'Kode'},
        { key: 'number', label: 'Nopol'},
        { key: 'vehicle.spb.number', label: 'SPB'},
        { key: 'vehicle.spb.contract_number', label: 'No Kontrak'},
        { key: 'branch.name', label: 'Wilayah'},
        { key: 'contact.name', label: 'Pemilik'},
        { key: 'variant.model', label: 'Model'},
        { key: 'variant.manufacture.name', label: 'Pabrikan'},
        { key: 'frame_number', label: 'No Rangka'},
        { key: 'machine_number', label: 'No Mesin'},
        { key: 'color', label: 'Warna'},
        { key: 'year', label: 'Tahun'},
        { key: 'is_active', label: 'Status Kendaraan', type:'span', data:{true:'Aktif', false:'Tidak Aktif'}},
      ],
      fieldsAdministrasi: [
        { key: 'stnk_number', label: 'No STNK'},
        { key: 'stnk_name', label: 'Nama STNK'},
        { key: 'stnk_date', label: 'Masa Berlaku STNK'},
        { key: 'stnk_address', label: 'Alamat STNK'},
        { key: 'bpkb_number', label: 'No BPKB'},
        { key: 'keur_date', label: 'KEUR Kadaluarsa'},
        { key: 'kim_date', label: 'KIM/IMK Kadaluarsa'},
        // { key: 'siup', label: 'SIUP Kadaluarsa'},
        { key: 'tera_date', label: 'Tanggal Tera Ulang'},
      ],
      fieldsOperasional: [
        { key: 'ratio_own_use', label: 'Ration Own Use'},
        { key: 'variant.km_initial_check', label: 'KM Initial'},
        { key: 'last_km', label: 'KM Akhir'},
        { key: 'average_km', label: 'Rata-rata KM'},
        { key: 'gps_number', label: 'No GSM GPS'},
        { key: 'last_km_date', label: 'Tanggal Terakhir Perawatan'},
      ],
      fieldsFisik: [
        { key: 'variant.tire_axle.name', label: 'Sumbu dan Posisi Ban'},
        { key: 'variant.capacity', label: 'Kapasitas'},
        { key: 'compartment', label: 'Konfigurasi Komparment'},
        { key: 'compartment_amount', label: 'Jumlah Komparment'},
        { key: 'variant.tire_size.name', label: 'Ukuran Ban'},
        { key: 'tank_material', label: 'Meterial Tangki'},

      ],
      is_update_stnk:false,
      is_update_keur:false,
      tax_date:moment().format('YYYY-MM-DD'),
      taxType:null,
      isAction:false
    }
  },
  created(){
    if(store.state.profile.info){
      var info = store.state.profile.info
      this.userType = info.contact_type.split('\\').pop().toLowerCase()
    }
  },
  methods:{
    showData(data){
      if(this.userType != 'staff'){
        if(data.stnk_date){
          this.is_update_stnk = false
          if(data.stnk_date && moment.duration(moment(data.stnk_date).diff(moment())).asDays() <= 30){
            this.is_update_stnk = true
          }
        }else{
          this.is_update_stnk = true
        }

        if(data.keur_date){
          this.is_update_keur = false
          if(data.keur_date && moment.duration(moment(data.keur_date).diff(moment())).asDays() <= 30){
            this.is_update_keur = true
          }
        }else{
          this.is_update_keur = true
        }

        if(!this.is_update_stnk && !this.is_update_keur)
          this.isAction = false
        else
          this.isAction = true
      }
    },
    async showDataOperasional(data){
      let maintenance = await this.$http.get("/vehicle/"+this.$route.params.id+"/maintenance")
      maintenance = maintenance.data.data
      if(maintenance.length > 0){
        data.last_km = maintenance[0].realisation_km
        data.last_km_date = maintenance[0].realisation_date
      }else
        data.last_km = data.variant.km_initial_check
    },
    showModalTax(tax){
      this.taxType = tax
      this.$refs['modal-request-tax'].show()
    },
    updateTax(){
      var params = {}
      if(this.taxType == 'stnk')
        params.stnk_date = this.tax_date
      else
        params.keur_date = this.tax_date

      this.$http.put(this.dataurl+'/'+this.$route.params.id, params).then(res=>{
        this.resetModal()
        this.$refs.infoAdministrasi.fetchData()
      })
    },
    resetModal(){
      this.tax_date = moment().format('YYYY-MM-DD')
      this.$refs['modal-request-tax'].hide()
    }
  }
}
</script>