<template>
  <b-card>
    <basetable
      ref="basetable"
      :dataurl="dataurl"
      :fields="fields"
      :is-edit="false"
      :is-delete="false"
      :is-add="false"
      title="Kelengkapan Kendaraan"
      :is-view="false"
      :isFilter="false"
      :isPagination="false"
    >
      <template #cell(is_exist)="data">
        <span
          class="badge "
          :class="data.item.is_exist?'bg-success':'bg-warning'"
        >
          {{ data.item.is_exist?"Ya":"Tidak" }}
        </span>
      </template>
      <template #cell(is_good)="data">
        <span
          class="badge "
          :class="data.item.is_good?'bg-success':'bg-warning'"
        >
          {{ data.item.is_good?"Ya":"Tidak" }}
        </span>
      </template>
      <template #cell(is_work)="data">
        <span
          class="badge "
          :class="data.item.is_work?'bg-success':'bg-warning'"
        >
          {{ data.item.is_work?"Ya":"Tidak" }}
        </span>
      </template>
    </basetable>
  </b-card>
</template>
<script>
import basetable from '@/views/base/BaseTable.vue'
import { BCard,BRow,BCol, BButton, BTable, BOverlay } from 'bootstrap-vue'

export default {
  components: {
    basetable,
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BOverlay
  },
  data() {
    return {
      dataurl:"/vehicle/"+this.$route.params.id+"/properties",
      fields: [
        { key: 'name', label: 'Nama Kelengkapan', sortable: true },
        { key: 'is_exist', label: 'Ada' },
        { key: 'is_good', label: 'Baik' },
        { key: 'is_work', label: 'Berfungsi' },
      ],
    }
  },
  methods:{
    show(){
      this.$refs.basetable.fetchData()
    },
  }
}
</script>
<style lang="">

</style>
