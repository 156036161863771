export const lineChartOptions = {
  dataLabels: {
    enabled: false
  },
  stroke: {
    curve: 'smooth'
  },
  xaxis: {
    type: 'datetime',
    categories: [],
  },
  tooltip: {
    x: {
      format: 'dd-MM-yyyy'
    },
  },
  chart: {
    toolbar: {
      show: false
    },
    zoom: {
      enabled: false
    }
  }
}

export const pieChartOptions = {
  responsive: [{
    breakpoint: 480,
    options: {
      chart: {
        width: 200
      },
      legend: {
        position: 'bottom'
      }
    }
  }],
  legend: {
    show: true,
    position: 'bottom'
  }
}