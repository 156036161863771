<template>
  <b-card>
    <b-row>
      <b-col class="2">
        <img 
          :src="urlImage"
          width="104"
          height="104"
          class="img-fluid rounded mr-1" 
        >
      </b-col>
      <b-col cols="10">
        <base-info
          :geturl="dataurl"
          :fields="fields"
          :col_single="false"
          :isedit="false"
          :isdelete="false"
          @readData="showData"
        />
      </b-col>
      <b-col cols="12">
        <hr>
      </b-col>
      <b-col cols="6">
        <div class="truncate">
          <h3 class="font-weight-bolder">
            Penggunaan BBM
          </h3>
        </div>
        <vue-apex-charts 
          ref="realtimeChartBBM"
          type="line" 
          height="250" 
          :options="chartOptionsComputed" 
          :series="seriesBBM"
        />
      </b-col>
      <b-col cols="6">
        <div class="truncate">
          <h3 class="font-weight-bolder">
            KM Tempuh
          </h3>
        </div>
        <vue-apex-charts 
          ref="realtimeChartKM"
          type="line" 
          height="250" 
          :options="chartOptionsComputed" 
          :series="seriesKM"
        />
      </b-col>
      <b-col cols="12">
        <br>
      </b-col>
      <b-col cols="6">
        <div class="truncate">
          <h3 class="font-weight-bolder">
            Issue
          </h3>
        </div>
        <b-row>
          <b-col cols="6">
            <div class="card bg-light-primary rounded">
              <div
                class="card-header"
                style="align-self: center; text-align: center;"
              >
                <div>
                  <h2 class="mb-0">
                    <b>{{ issue_open }}</b>
                  </h2>
                  <p class="card-text">
                    Baru
                  </p>
                </div>
              </div>
            </div>
          </b-col>
          <b-col cols="6">
            <div class="card bg-light-primary rounded">
              <div
                class="card-header"
                style="align-self: center; text-align: center;"
              >
                <div>
                  <h2 class="mb-0">
                    <b>{{ issue_overdue }}</b>
                  </h2>
                  <p class="card-text">
                    Lewat Tanggal
                  </p>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        <hr style="margin:0;">
        <div
          v-for="(row, index) in dataIssue"
          :key="index"
          class="d-flex"
          style="align-items: center; margin-top:10px;"
        >
          <b-avatar
            class="img-fluid rounded mr-1"
            rounded="sm"
            variant="light-primary"
            size="30"
          >
            <feather-icon
              size="20"
              icon="InfoIcon"
            />
          </b-avatar>
          <div class="d-flex flex-column">
            <div class="user-info">
              <p
                style="margin: 0;"
                class="card-text mr-1"
              >
                <b>{{ row.name }}</b>
              </p>
              <p
                style="margin: 0;"
                class="card-text mr-1"
              >
                Laporan : Joko Prianto, {{ row.plan_date }}
              </p>
            </div>
          </div>
        </div>
      </b-col>
      <b-col cols="6">
        <div class="truncate">
          <h3 class="font-weight-bolder">
            Services
          </h3>
        </div>
        <b-row>
          <b-col cols="6">
            <div class="card bg-light-primary rounded">
              <div
                class="card-header"
                style="align-self: center; text-align: center;"
              >
                <div>
                  <h2 class="mb-0">
                    <b>{{ service_open }}</b>
                  </h2>
                  <p class="card-text">
                    Baru
                  </p>
                </div>
              </div>
            </div>
          </b-col>
          <b-col cols="6">
            <div class="card bg-light-primary rounded">
              <div
                class="card-header"
                style="align-self: center; text-align: center;"
              >
                <div>
                  <h2 class="mb-0">
                    <b>{{ service_overdue }}</b>
                  </h2>
                  <p class="card-text">
                    Lewat Tanggal
                  </p>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        <hr style="margin:0;">
        <div
          v-for="(row, index) in dataService"
          :key="index"
          class="d-flex"
          style="align-items: center; margin-top:10px;"
        >
          <b-avatar
            class="img-fluid rounded mr-1"
            rounded="sm"
            variant="light-primary"
            size="30"
          >
            <feather-icon
              size="20"
              icon="CalendarIcon"
            />
          </b-avatar>
          <div class="d-flex flex-column">
            <div class="user-info">
              <p
                style="margin: 0;"
                class="card-text mr-1"
              >
                <b>{{ row.name }}</b>
              </p>
              <p
                style="margin: 0;"
                class="card-text mr-1"
              >
                Estimasi {{ row.plan_date }}
              </p>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>

import {BCard, BRow, BCol, BAvatar} from 'bootstrap-vue'
import BaseInfo from '@/views/base/BaseInfo.vue'
import VueApexCharts from 'vue-apexcharts'
import { lineChartOptions } from '../../chartOptions'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BaseInfo,
    VueApexCharts,
    BAvatar
  },

  data() {
    return {
      dataurl:"/vehicle",
      baseroute:"vehicles",
      fields: [
        { key: 'number', label: 'Nomor Polisi'},
        { key: 'variant.model', label: 'Model'},
        { key: 'year', label: 'Tahun'},
        { key: 'branch.name', label: 'Cabang'},
      ],
      seriesBBM: [],
      seriesKM: [],
      dataIssue:[],
      dataService:[],
      issue_open:0,
      issue_overdue:0,
      service_open:0,
      service_overdue:0,
      // urlImage:require('@/assets/images/avatars/logo_Avatar.png')
      urlImage: '/large-logo.png',
    }
  },
  computed: {
    chartOptionsComputed() {
      const options = JSON.parse(JSON.stringify(lineChartOptions))
      return options
    },
  },
  mounted(){
    this.fetchData()
  },
  methods:{
    showData(data){
      // if(data.photos.length > 0)
      //   this.urlImage = data.photos[0].file_url
    },
    fetchData(){
      this.$http.get(this.dataurl+'/'+this.$route.params.id+'/dashboard').then(res=>{
        let data = res.data
        this.dataIssue = data.issues
        this.dataService = data.services
        this.issue_open = data.maintenance.issue_open
        this.issue_overdue = data.maintenance.issue_overdue
        this.service_open = data.maintenance.service_open
        this.service_overdue = data.maintenance.service_overdue
        this.fetchDataBBM(data.fuel)
        this.fetchDataKM(data.odometer)
      })
    },
    fetchDataBBM(result){
      let data = []
      let label = []
      this.labelBBM = []
      for(let i=0; i<result.length; i++){
        data.push(parseFloat(result[i].liter))
        label.push(result[i].date)
      }
      this.seriesBBM = [{
        name: 'Liter',
        data: data
      }]
      // if(label.length > 0){
      this.$refs.realtimeChartBBM.updateOptions({ xaxis: {categories: label} })
      // }
    },
    fetchDataKM(result){
      let data = []
      let label = []
      for(let i=0; i<result.length; i++){
        data.push(parseFloat(result[i].km))
        label.push(result[i].date)
      }
      this.seriesKM = [{
        name: 'KM',
        data: data
      }]
      // if(label.length > 0){
      this.$refs.realtimeChartKM.updateOptions({ xaxis: {categories: label} })
      // }
    }

  }
}
</script>