<template>
  <b-card>
    <basetable
      ref="basetable"
      :dataurl="dataurl"
      :fields="fields"
      :is-edit="false"
      :is-delete="false"
      :is-add="false"
      :is-view="false"
      :is-action="false"
      title="History Mutasi"
    >
      <template #cell(status)="data">
        <span
          class="badge "
          :class="data.item.status == 'requested'?'bg-warning':'bg-success'"
        >
          {{ data.item.status }}
        </span>
      </template>
    </basetable>
  </b-card>
</template>
<script>
import basetable from '@/views/base/BaseTable.vue'
import { BCard,BRow,BCol, BButton, BTable, BOverlay } from 'bootstrap-vue'

export default {
  components: {
    basetable,
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BOverlay
  },
  data() {
    return {
      dataurl:"/vehicle/"+this.$route.params.id+"/mutation",
      fields: [
        { key: 'destination.name', label: 'Cabang Requestor'},
        { key: 'origin.name', label: 'Cabang Pengirim'},
        { key: 'request_date', label: 'Tgl Permintaan'},
        { key: 'number', label: 'No Permintaan'},
        // { key: 'vehicles_count', label: 'Qty Kendaraan'},
        { key: 'status', label: 'Status'},
      ],
    }
  },
  methods:{
    show(){
      this.$refs.basetable.fetchData()
    },
  }
}
</script>
<style lang="">

</style>
