<template>
  <b-row>
    <b-col v-if="title != ''">
      <strong><h3><span v-if="titleIcon != ''"><feather-icon :icon="titleIcon" />&nbsp;</span>{{ title }}</h3></strong>
      <br>
    </b-col>
    <b-col
      v-for="(field, index) in fields"
      :key="index"
      :cols="col_single?'12':'6'"
    >
      <!-- with helper text -->
      <form-label
        :label="field.label"
        :content="objectView(field.key)"
        :bold="field.bold"
        :type="field.type"
        :data-content="field.data"
        :format-date="field.format"
      />
    </b-col>
    <b-col
      v-if="isedit || isdelete || isAction"
      cols="12"
    >
      <hr>
      <b-button
        v-if="isedit"
        class="mr-1"
        size="sm"
        variant="primary"
        type="button"
        @click.prevent="editForm"
      >
        <span>Edit</span>
      </b-button>
      <slot name="buttonaction" />
      <b-button
        v-if="isdelete"
        class="mr-1"
        size="sm"
        variant="danger"
        type="button"
        @click.prevent="confirmDelete"
      >
        
        <span>Hapus</span>
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import {BRow, BCol, BButton} from 'bootstrap-vue'
import FormLabel from '@/views/base/form/FormLabel.vue'

export default {
  components: {
    BRow,
    BCol,
    FormLabel,
    BButton
  },
  props: {
    geturl: {
      type: String,
      default: '',
    },
    baseroute: {
      type: String,
      default: '',
    },
    fields: {
      type: Array,
      default() {
        return []
      },
    },
    dataitem: {
      type: Object,
      default: function () {
        return {}
      }
    },
    isedit: {
      type: Boolean,
      default: true
    },
    isdelete: {
      type: Boolean,
      default: true
    },
    isAction:{
      type: Boolean,
      default: false
    },
    col_single:{
      type:Boolean,
      default:true
    },
    title: {
      type: String,
      default: '',
    },
    titleIcon: {
      type: String,
      default: '',
    },
    dataId:{
      type:String,
      default:''
    }
  },
  data() {
    return {
      model:{},
      data_id:''
    }
  },
  beforeMount(){
    if(this.geturl != '')
      this.fetchData()
    else
      this.model = this.dataitem
  },
  methods: {
    fetchData(){
      if(this.$route.params.id != null){
        this.data_id = this.dataId != ''?this.dataId:this.$route.params.id
        this.$http.get(this.geturl+'/'+this.data_id).then(res=>{
          this.model = res.data
          this.$emit("readData", this.model)
        })
      }
    },
    objectView(key){
      let row = ""
      if(key.includes(".")){
        let val = this.model
        for (let i = 0; i < key.split(".").length; i++) {
          if(val == null){
            row = ""
          }else{
            row = val[key.split(".")[i]]
            if(typeof row === 'object')
              val = row
          }
        }
      }else{
        row = this.model[key]
      }

      if(row == "" || row == null)
        row = '-'
      return String(row)
    },
    editForm(){
      this.$router.push({ name: this.baseroute+'-edit',params : { id: this.$route.params.id} })

      // this.dataitem = data.item
      // this.$refs['my-modal-form'].show()
    },
    confirmDelete(){
      const id = this.$route.params.id
      let model = this.model
      model = model.name?model.name:model.number?model.number:model.subject?model.subject:model.number_plate?model.number_plate:''
      this.$bvModal
        .msgBoxConfirm('Are you sure delete '+model+' ?', {
          title: 'Please Confirm',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
          okVariant:'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
        })
        .then(confirm => {
          if(confirm){
            this.$http.delete(this.geturl+'/'+id)
              .then(() => {
                this.$router.go(-1)
                // this.$router.push({ name: this.baseroute})
                // this.fetchData()
                  // this.items = data.data
                  // this.recordsTotal = data.recordsTotal
                  // this.loading =false
  
            })
          }
        })
    }
  }
}
</script>
